import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const CallToAction = () => {
    return (
      <section className="call-to-action">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-8 col-sm-12">
              <h5>Let us guide your home</h5>
              <h1>
                WE'LL MAKE SURE YOUR REAL ESTATE PROPERTY GETS IN FRONT OF THE RIGHT PEOPLE
              </h1>
              <Link to='/find-a-home'>
                <Button className="explore-btn">EXPLORE PROPERTIES</Button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
};

export default CallToAction;