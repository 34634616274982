import ShowMoreText from "react-show-more-text";

const TestimonialBox = (props) => {
  return (
    <div className="testimonial-box">
      <h5>{props.title}</h5>
      <ShowMoreText
        lines={11}
        more="Continue reading"
        less="Show less"
        className="content-css"
        anchorClass="show-more-less-clickable"
        expanded={false}
      >
        {props.description}
      </ShowMoreText>
      <div className="testimonial-profile">
        <img src={props.userProfile} alt={props.name} />
        <div className="profile-details">
          <h5>{props.name}</h5>
          <p>{props.userRole}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="container">
        <div className="heading">
          <h2>WHAT CLIENTS SAY ABOUT ME</h2>
          <h5>
            Read what our satisfied clients have to say about their experience
            working with me.
          </h5>
        </div>
        <div className="row mt-4">
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Best Buying Experience"
              description="Admitting our own demands, my husband and I were intricate customers in the realm of home purchases. This complexity was compounded by our quest for a Southern California residence while situated in Washington State. Our particular prerequisites and financial limits led Michael to patiently escort us through numerous virtual home tours. Throughout this, his attentiveness and responsiveness shone, readily overcoming personal or technical obstacles that could impede negotiations. We genuinely appreciate Michael for guiding us to secure our dream home, even within this fiercely competitive seller's market. His persistence over these last 5 months made it all achievable. Cheers to him!"
              userProfile={require("../assets/testimonial-1.jpg")}
              name="Edith Kao-Andres"
              userRole="Chino Hills, CA 2021"
            />
          </div>
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Great Experience"
              description="I just finished my escrow with Michael Avendano, and I do not have any regrets hiring him to represent me in my transaction. Prior to retaining his service, I went through at least 2-3 well known Real Estate agents who were knowledgeable of the area, with at least 15-20 offers, but with Michael we only had to put 3 offers and ours was accepted. He has been in the Industry for a longtime and I highly recommend for anyone who retains his service, you will not be disappointed. Thank you Michael A. for your great service and due diligence for a smooth transaction closing."
              userProfile={require("../assets/testimonial-2.jpg")}
              name="Duminda W."
              userRole="Rancho Palos Verdes, CA 2020"
            />
          </div>
          <div className="col-xl-4 col-sm-12">
            <TestimonialBox
              title="Easy Buying Experience"
              description="As first time home buyers our apprehension of the entire home buying process delayed us buying a home for years. We didn’t want to be another couple with a bad real estate agent experience like so many others. Michael’s professionalism, knowledge of the home market and real estate acumen provided us with a tremendous home buying experience. Our friends and family are amazed when we tell them we’re already looking forward to our next home search years from now. Michael will be our only choice for all of our real estate needs and we can’t recommend him enough."
              userProfile={require("../assets/testimonial-3.jpg")}
              name="Marin Family"
              userRole="Whittier, CA 2019"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
