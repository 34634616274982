/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

/* APIS */
import { getBlogDetailsById } from "../../service/api";
import BlogSidebar from "../../components/blogs-sidebar";

const BlogDetail = () => {
    const { id } = useParams();
    const getId = id?.split("&");
    const [loading, setLoading] = useState(true);
    const [blogDetails, setBlogDetails] = useState(null);

    useEffect(() => {
      getBlogDetails();
    }, [getId[0]]);

    /* GET BLOG DETAILS */
    const getBlogDetails = () => {
      setLoading(true);
      getBlogDetailsById(getId[0])
        .then((res) => {
          if (res?.status) {
            setBlogDetails(res?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        })
        .finally((err) => {
          setLoading(false);
        });

      return () => {
        setBlogDetails(null);
      };
    };

  return (
    <section className="blog-detail">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-sm-12">
            <div className="blog-detail-desc">
              <h1>{blogDetails?.name}</h1>
              <div className="description-inner">
                <p>{ReactHtmlParser(blogDetails?.discription)}</p>
              </div>
            </div>
          </div>

          {/* SIDEBAR */}
          <BlogSidebar />
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
